import React from 'react'

import {Link, useStaticQuery, graphql} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Icon from './icon'
import SearchIcon from '@material-ui/icons/Search';

import {cn} from '../lib/helpers'

import SearchBar from './search-bar'

import * as styles from './header.module.css'


export const query = graphql`
query {
  strapi {
    siteSections(sort: "order:asc") {
      id
      section
      order
      show_in_menu
      page {
        slug
      }
      external_url
    }
    siteSetting {
      id
      site_name
      description: default_seo_description
      logo {
        url
        alternativeText
        caption
        imageFile{
          childImageSharp{
            gatsbyImageData(
              quality: 100
              placeholder: NONE
            )
          }
          extension
          publicURL
        }
      }
    }
  }
}
`

function ShowSection ({section}) {
  if (section.show_in_menu && (section.page || section.external_url) ){
    return (
      <li key={section.id}>
        <>
        {section.page &&
          <Link to={`/${section.page.slug}/`}>{section.section}</Link>
        }
        { !section.page && section.external_url &&
          <Link to={`${section.external_url}`}>{section.section}</Link>
        }
        </>
      </li>
    )
  }
  return ( <></>)
}
function showLogo(data, siteTitle) {

  if ( data.strapi.siteSetting.logo == null ){
    return(data.strapi.siteSetting.site_name)
  }

  const logoImage = data.strapi.siteSetting.logo && getImage(data.strapi.siteSetting.logo.imageFile)

  if (data.strapi.siteSetting.logo.imageFile.extension == 'svg'){
    return( <img className={styles.logo} src={data.strapi.siteSetting.logo.imageFile.publicURL} alt={data.strapi.siteSetting.site_name} /> )
  } else {
    return( <GatsbyImage image={logoImage} alt={siteTitle} className={styles.logo} /> )
  }
}

const Header = ({
  siteTitle, 
  onHideNav, onShowNav, showNav, 
  onHideNavSearch, onShowNavSearch, showNavSearch}) => {
  const data = useStaticQuery(query);
  const siteSections = data.strapi.siteSections
  
  return (
    <>
    <div className={styles.header}>
      <div className={styles.wrapper} id="navbar">

        <div className={styles.navButton}>
          <button aria-label="Toggle Menu On Off" className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
            <Icon symbol='hamburger' />
          </button>
          </div>

        <div className={styles.branding}>
          <Link to='/'>
            {showLogo(data, siteTitle)}
          </Link>
        </div>

        <div className={styles.navButtonSearch}>
        {process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS &&
        <button aria-label="Toggle Search" className={styles.toggleNavButton} onClick={showNavSearch ? onHideNavSearch : onShowNavSearch}>
          <SearchIcon></SearchIcon>
        </button>
        }
        </div>

      </div>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <div className={styles.closebtn} onClick={showNav ? onHideNav : onShowNav} title="Close Menu">x</div>
          <div className={styles.overlaycontent} onClick={showNav ? onHideNav : onShowNav}>
            <ul>
              <li key="home">
                <Link to='/'>Home</Link>
              </li>
              {siteSections &&
                siteSections.map(section => 
                  <span key={section.id}>
                    <ShowSection section={section}></ShowSection>
                  </span>
              )}
              { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS &&
                <li key="search">
                  <Link to='/search/'>Search</Link>
                </li>
              }
              <li key="about">
                <Link to='/about/'>About</Link>
              </li>
              <li key="contact">
                <Link to='/contact/'>Contact</Link>
              </li>
              <li key="privacy">
                <Link to='/privacy/'>Privacy</Link>
              </li>
            </ul>
          </div>
        </nav>


        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS &&
        <nav className={cn(styles.navSearch, showNavSearch && styles.showNavSearch)}>
          <span className={styles.closebtn} onClick={showNavSearch ? onHideNavSearch : onShowNavSearch} title="Close Search">x</span>
          <div className={styles.overlaycontent}>
            <ul><li>Search</li></ul>
            <SearchBar id="searchBarNav"></SearchBar>
          </div>
        </nav>
        }
      
    </div>
    </>
  )
}

export default Header