import {isFuture} from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

export function getPageUrl ({canonical_url=null, page_type, slug}) {
  if (canonical_url){
    return canonical_url
  } else {
    // determine the url using slug and category
    if (page_type?.slug_prefix){
      return `/${page_type?.slug_prefix}/${slug}/`
    } else {
      return `/${slug}/`
    }
  }
}

export function getEntityUrl ({slug}) {

  // todo, update about to the correct prefix
  // also only include a url if it is set to create a page
  // otherwise show the 'primaryPage' for this entity
  
  return `/about/${slug}/`
}

export function buildImageObj (source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText (blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}
