import React from 'react'

import {Link, useStaticQuery, graphql} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from './container';

import * as styles from './footer.module.css'


export const query = graphql`
query {
  strapi {
    siteSections(sort: "order:asc") {
      id
      section
      order
      show_in_menu
      page {
        slug
      }
      external_url
    }
    siteSetting {
      id
      site_name
      description: default_seo_description
      logo {
        url
        alternativeText
        caption
        imageFile{
          childImageSharp{
            gatsbyImageData(
              quality: 100
              placeholder: NONE
            )
          }
          extension
          publicURL
        }
      }
    }
  }
}
`

function ShowSection ({section}) {
  if (section.show_in_menu && (section.page || section.external_url) ){
    return (
      <li key={section.id}>
        <>
        {section.page &&
          <Link to={`/${section.page.slug}/`}>{section.section}</Link>
        }
        { !section.page && section.external_url &&
          <Link to={`${section.external_url}`}>{section.section}</Link>
        }
        </>
      </li>
    )
  }
  return ( <></>)
}
function showLogo(data, siteTitle) {

  if ( data.strapi.siteSetting.logo == null ){
    return(data.strapi.siteSetting.site_name)
  }

  const logoImage = data.strapi.siteSetting.logo && getImage(data.strapi.siteSetting.logo.imageFile)

  if (data.strapi.siteSetting.logo.imageFile.extension == 'svg'){
    return( <img className={styles.logo} src={data.strapi.siteSetting.logo.imageFile.publicURL} alt={data.strapi.siteSetting.site_name} /> )
  } else {
    return( <GatsbyImage image={logoImage} alt={siteTitle} className={styles.logo} /> )
  }
}

const Header = ({
  siteTitle, 
  onHideNav, onShowNav, showNav, 
  onHideNavSearch, onShowNavSearch, showNavSearch}) => {
  const data = useStaticQuery(query);
  const siteSections = data.strapi.siteSections
  
  return (
    <>
    </>
  )
}

export default Header