
import React, {useState} from 'react'

import {graphql, StaticQuery} from 'gatsby'

import Layout from '../components/layout'


const query = graphql`
  query SiteTitleQuery {
    site: strapi { siteSetting{
      title: site_name
    }}
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  const [showNavSearch, setShowNavSearch] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  function handleShowNavSearch () {
    setShowNavSearch(true)
  }
  function handleHideNavSearch () {
    setShowNavSearch(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            showNavSearch={showNavSearch}
            siteTitle={data.site.siteSetting.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            onHideNavSearch={handleHideNavSearch}
            onShowNavSearch={handleShowNavSearch}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
