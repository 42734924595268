import React, { Component } from "react";

import * as styles from './search-bar.module.css'




class SearchBar extends Component {
  constructor() {
    super();
    this.state = {};
    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onInputchange(event) {
    this.setState({
      ["search"]: ('"'+event.target.value+'"').replace('""','"')
    });
  }

  onSubmitForm() {
    var query = ""
    if (this.state.search) {
      query = this.state.search
    }
    window.location.href = "/search/?q=\""+query+"\"";
  }
  render() {

    return (
      <div className={styles.searchBar}>
        <form action="/search/" className={styles.searchForm} autoComplete="off" >
              <input
                className={styles.searchInput}
                type="text"
                onChange={this.onInputchange}
                placeholder="Search..."
                autoComplete="off"
              />
              <input
                id="searchInput"
                type="hidden"
                name="q"
                value={this.state.search}
              />
            <button id={"searchButton_"+Math.random()} className={styles.searchButton} placeholder="Search..." onClick={this.onSubmitForm}>Submit</button>
        </form>
      </div>
    );
  }
}

export default SearchBar;