// extracted by mini-css-extract-plugin
export var branding = "header-module--branding--0dK2q";
export var closebtn = "header-module--closebtn--rAHtO";
export var header = "header-module--header--8PkXS";
export var logo = "header-module--logo--2PqPm";
export var nav = "header-module--nav--MsSnd";
export var navButton = "header-module--navButton--QgCnZ";
export var navButtonSearch = "header-module--navButtonSearch--cW+NY";
export var navSearch = "header-module--navSearch--6yK5u";
export var overlaycontent = "header-module--overlaycontent--3Nt8X";
export var root = "header-module--root--uHg2P";
export var rootSpacer = "header-module--rootSpacer--poQaS";
export var searchButton = "header-module--searchButton--nG0pH";
export var searchInput = "header-module--searchInput--HorX1";
export var showNav = "header-module--showNav--DELyL";
export var showNavSearch = "header-module--showNavSearch--TlGfG";
export var toggleNavButton = "header-module--toggleNavButton--QHj3z";
export var wrapper = "header-module--wrapper--iYf5a";